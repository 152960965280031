<template lang="pug">
  include ../plugins/pug/btn

  section.section
    div.container
      div.title__panel
        div.title__input
          p About us
        +btn("Read More", "about").btn__primary.title__btn

      div.about__counter
        div(
          v-for="(item, index) in counterItems"
          :key="index"
        ).about__counter-item
          div.counter__box
            CounterBlock(:target-number="item.counterNumber")
            span(v-html="item.counterSign")
          div.about__counter-title {{ item.counterTitle }}

      div.content-block
        div.content__block-img
          div.img__wrapp
            img(
              :src="AboutImg"
              alt="about image"
            )
        div.content__block-body.text-xl.align-self-center
          p Disoft is a hub of innovations and technologys, headquartered in New York. Our diverse team of over 100 specialists excels in delivering exceptional solutions, leveraging their expertise in development, design, marketing, and management.
          p In addition to our headquarters, we have established multiple offices across Europe, strategically positioned to serve clients globally.
          p At Disoft we are passionate about pushing the boundaries of technology and delivering cutting-edge software solutions that drive business growth and success.
</template>

<script>
// images
import AboutImg from '@/assets/img/about-img.png'
export default {
  name: 'AboutBlock',
  components: {
    CounterBlock: () => import('@/components/CounterBlock.vue')
  },
  data () {
    return {
      AboutImg,
      counterItems: [
        {
          counterNumber: 10,
          counterSign: '+',
          counterTitle: 'Years Experience'
        },
        {
          counterNumber: 100,
          counterSign: '+',
          counterTitle: 'IT Specialists'
        },
        {
          counterNumber: 120,
          counterSign: '+',
          counterTitle: 'Projects Delivered'
        },
        {
          counterNumber: 16,
          counterSign: '<strong>k</strong>+',
          counterTitle: 'Hours of Support Provided'
        }
      ]
    }
  }
}
</script>
